/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 26, 2023 */



@font-face {
    font-family: 'montserratblack';
    src: url('montserrat-black-webfont.woff2') format('woff2'),
        url('montserrat-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratbold';
    src: url('montserrat-bold-webfont.woff2') format('woff2'),
        url('montserrat-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratextrabold';
    src: url('montserrat-extrabold-webfont.woff2') format('woff2'),
        url('montserrat-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratextralight';
    src: url('montserrat-extralight-webfont.woff2') format('woff2'),
        url('montserrat-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratlight';
    src: url('montserrat-light-webfont.woff2') format('woff2'),
        url('montserrat-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratmedium';
    src: url('montserrat-medium-webfont.woff2') format('woff2'),
        url('montserrat-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratregular';
    src: url('montserrat-regular-webfont.woff2') format('woff2'),
        url('montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratsemibold';
    src: url('montserrat-semibold-webfont.woff2') format('woff2'),
        url('montserrat-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratthin';
    src: url('montserrat-thin-webfont.woff2') format('woff2'),
        url('montserrat-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}